import React from "react"
import { graphql } from "gatsby"
import { Container, Row } from "reactstrap"

import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import PageTitle from "../components/page-title"

import MenuPage from "../templates/MenuTemplate"

const CateringMenu = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Catering Menu"
        keywords="ribsusabbq, ribs usa, ribs usa bbq, ribs, usa, bbq, best bbq near me, baby back ribs, beef ribs near me, best beef ribs near me, bbq chicken near me"
      />
      <PageTitle title="Catering Menu" />
      <section id="list-menu">
        <Container>
          <Row className="up-menu">
            <div className="col-12 col-sm-12">
              <div className="text-center">
                <a
                  className="btn my-button"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://hhsclientfiles.s3.amazonaws.com/ribsusa/Ribs-Usa-Menu.pdf"
                  download
                >
                  <span className="myover">Download Our Menu</span>
                </a>
              </div>
            </div>
          </Row>
        </Container>
        <MenuPage data={data} />
      </section>
    </Layout>
  )
}

export default CateringMenu

export const query = graphql`
  {
    allInternalResData(
      filter: {
        name: {
          in: [
            "LARGE PARTY MENU"
            "SIDE ORDER"
            "Salads"
            "MEATS"
            "Dont Forget your HOT WINGS!"
          ]
        }
      }
    ) {
      nodes {
        name
        description
        id
        items {
          name
          description
          id
          price
          sizes {
            name
            price
            menu_item_id
          }
        }

        groups {
          name
          menu_id
          options {
            price
            name
            id
          }
        }
      }
    }
  }
`
